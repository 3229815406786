﻿
//handling free trial modal
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const apiURL = "https://api.slidize.ai/api/com/trialLicense";
function validateEmail(email) {
    return emailPattern.test(email.trim());
}

function sendFreeTrialRequest()
{
    //email validation
    const email = document.getElementById('trialRequestEmail');
    if (!validateEmail(email.value)) {
        email.setCustomValidity('Please enter a valid email address.');
        email.reportValidity();
        return;
    }

    //license selection validation
    const selectedCheckboxes = document.querySelectorAll('#free-trial-license-list .form-check-input:checked');
    const selectedValues = Array.from(selectedCheckboxes).map(checkbox => checkbox.value);
    if (selectedValues.length === 0){
        const allCheckboxes = document.querySelectorAll('#free-trial-license-list .form-check-input');
        allCheckboxes[0].setCustomValidity('Please select at least one option.');
        allCheckboxes[0].reportValidity();
        return;
    }

    const button = document.getElementById('trialRequestButton');
    const buttonText = document.getElementById('buttonText');
    const spinner = document.getElementById('spinner');
    button.disabled = true;
    buttonText.style.display = 'none';
    spinner.style.display = 'inline-block';

    const body = {
        email: email.value,
        licenseList: selectedValues
    }
    
    fetch(apiURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok){
            alert("Something went wrong, please try again later.");
            const errorData = response.json();
            console.log(`Failed to send request: ${errorData.message || 'Unknown error'}`);
        }
        else
        {
            const form = document.getElementById('trialRequestForm');
            const formSuccess = document.getElementById('trialRequestFormSuccess');
            form.hidden = true;
            formSuccess.hidden = false;
        }
    }).catch(error => {
        alert("Something went wrong, please try again later.");
        console.log(`Failed to send request: ${error.message}`);
    }).
    finally(() => {
        button.disabled = false;
        spinner.style.display = 'none';
        buttonText.style.display = 'inline-block';
    })
}

document.addEventListener('DOMContentLoaded', () => {

    //reset form when modal is closed
    const modal = document.getElementById("modalFreeTrial");
    modal.addEventListener('hidden.bs.modal', function () {
        const form = document.getElementById('trialRequestForm');
        const formSuccess = document.getElementById('trialRequestFormSuccess');
        form.hidden = false;
        formSuccess.hidden = true;

        const licenseList = document.getElementById("free-trial-license-list");
        licenseList.scrollTop = 0;
        
        const selectedCheckboxes = licenseList.querySelectorAll('.form-check-input:checked');
        selectedCheckboxes.forEach(function(checkbox) {
            checkbox.checked = false;
        });
        
        
    });

    const link = document.getElementById('trialRequestButton');

    link.addEventListener('click', (event) => {
        event.preventDefault();
        sendFreeTrialRequest();
    });
});
